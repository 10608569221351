<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      salary_items: [],
      SalaryItemsBranches: {},
      salary_items_branches: [],
      branch: [],
      page: 1,
      limit: 20,

      searchModel: "",
      tot_pages: 0,
      searchSalaryItemsBranches: null,
      SalaryItemsBranchesSearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addSalaryItemsBranches() {
      var data = {
        title: "popups.addsalaryitembranch",
        inputs: [
          {
            model: "salary_item_id",
            type: "select",
            options: (() => {
              return this.salary_items.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.salaryitem",
          },

          {
            model: "branch_id",
            type: "select",
            options: (() => {
              return this.branch.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.branch",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("salary-items-branches", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editSalaryItemsBranches(app) {
      var data = {
        title: "popups.editsalaryitembranch",
        inputs: [
          {
            model: "salary_item_id",
            type: "select",
            options: (() => {
              return this.salary_items.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.salaryitem",
            value: app.salary_item_id,
          },

          {
            model: "branch_id",
            type: "select",
            options: (() => {
              return this.branch.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.branch",
            value: app.branch_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("salary-items-branches", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SalaryItemsBranchesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("salary-items-branches/search", {
          search: this.searchModel,

          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          var salary = [];

          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].salary_items_branches.length; j++) {
              salary.push(res.data[i].salary_items_branches[j]);
            }
          }
          this.salary_items_branches = salary;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.SalaryItemsBranchesSearchMode = false;
      this.get(this.page);
    },
    getSalaryItemsBranches() {
      this.http.get("salary-items").then((res) => {
        this.salary_items = res.data;
      });
    },
    getBranches() {
      this.http.get("branches").then((res) => {
        console.log("#####", res.data);
        this.branch = res.data;
      });
    },
    deleteSalaryItemsBranches(app) {
      var data = {
        title: "popups.delsalaryitembranch",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("salary-items-branches", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("salary-items-branches/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.salary_items_branches = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getSalaryItemsBranches();
    this.getBranches();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.salaryitemsbranches.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="SalaryItemsBranchesSearchMode"
          @click="cancelappsearchMode()"
        ></button>

        <button
          type="button"
          @click="addSalaryItemsBranches()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("salary_items_branches.id") }}</th>
          <th scope="col">{{ $t("salary_items_branches.salaryitem") }}</th>
          <th scope="col">{{ $t("salary_items_branches.branch") }}</th>
          <th scope="col">{{ $t("salary_items_branches.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in salary_items_branches"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app.salary_item?.name }}</td>
          <td>{{ app.branch?.name }}</td>

          <td>
            <a
              class="btn btn-primary me-2"
              @click="editSalaryItemsBranches(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger me-2"
              href="javascript: void(0);"
              role="button"
              @click="deleteSalaryItemsBranches(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->

    <!--   Apps  Pagination     -->
    <div v-if="tot_pages == 20">
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>
<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
